// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuServicesOverviewScss from "./EnzuServicesOverview.scss";

var css = EnzuServicesOverviewScss;

function EnzuServicesOverview(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "Why Enzu Bare Metal?"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Navigating the digital age can feel overwhelming, and that's where Enzu comes in. Our mission is to make this transition easier by simplifying technology and introducing tools that promote smoother sailing into cloud computing services."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "We understand that technology often holds huge potential, but it can also be intimidating or unknowable — and those are two roads we want to help you and your business avoid. Our aim is to make sure you're as prepared and up to speed as possible."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "With our personalized bare metal servers tailored to your exact needs, you'll find your ideal deployment in record time. The future of technology is here, and we want to make sure you have what you need to join in."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Infrastructure as a Service at Your Fingertips"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Get ready to turbocharge your workloads and boost performance with Enzu's Bare Metal Cloud Servers! Our reliable server solutions can handle all of your most intensive processes, providing enhanced control of your cloud infrastructure."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Bespoke infrastructure and customizable configurations provide powerful solutions tailored to suit any need — from deployment to optimization. Plus, our servers are one hundred percent dedicated exclusively to you. Stop waiting and catapult your success with Enzu. Get the perfect solutions for tough applications and workflows with our fully customized services!"
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Revolutionize Your Server with Our Customization Wizard"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Effortlessly create your perfect server with single processor, dual processor, or hardware RAID Options. Customize your networking and storage configuration and select your management level with ease. Harness our engineering expertise for a smooth and seamless server operation experience."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Customizing bare metal solutions allows companies to save money in several ways. Unlike pre-packaged solutions, custom solutions are specifically tailored to meet the company's specific needs. This means that the whole system is set up for the company's jobs. That makes it easier to use less resources, do better work, and spend less money on hardware."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Custom solutions are built to grow with the company. As the company gets bigger and needs more help, it can add more resources to what it already has instead of buying a completely new solution. This not only saves money, but also reduces downtime and minimizes the risk of compatibility issues."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Experience unparalleled performance, reliability, and security with dedicated server hosting that's customized to your unique requirements. Say goodbye to \"noisy neighbors\" and hello to optimized computing power that's all yours. With single tenancy, you're guaranteed the high performance you deserve."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Say Goodbye to Overpriced IT Expenses"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "By freeing trapped funds in your capital investments, you can move away from the outdated assets that are holding back your organization. No more guesswork around ambitious IT investments."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Instead of an intangible financial strain, focus on effective solutions with automatic subscription billing for on-demand resources with costs you're comfortable with! Enzu IaaS bare metal solutions allow you to forego huge upfront payments and get a clearer estimation of where and how you'd spend your money. "
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Unlock newfound cost predictability and greater control over yourexpenditure by only paying for the resources when you truly require them."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Your Solution for Low-Latency Deployments"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Low latency deployments are beneficial for companies regardless of the sector they operate in. Low latency refers to the amount of time it takes for data to travel between its source and destination, and it is critical for businesses that depend on real-time processing, such as finance, gaming, or healthcare."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "With low latency deployments, these companies can achieve much faster data processing and transmission times, which can improve the overall user experience and lead to increased customer satisfaction. Lower latency also reduces the likelihood of network delays, which can be especially important for businesses that rely on cloud-based applications, data analytics, or video conferencing."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "If you want real-time access to data feeds, virtual machines just won't cut it. Consumer mobile apps, gaming platforms, and other applications that require extreme low-latency performance demand the power of bare metal infrastructure. Say goodbye to lag and hello to faster, more reliable deployments with Enzue Bare Metal Cloud."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    })
              ],
              className: css.servicesOverview
            });
}

var make = EnzuServicesOverview;

export {
  css ,
  make ,
}
/* css Not a pure module */
